import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { isConditionMatching, isLast30DaysMatching } from './filterUtil';

export default (keyword, filter, showTerminated, filterSort, filterMap, positions, setShowTerminated, setFilteredDevices, setFilteredPositions, distanceUnit) => {
  const groups = useSelector((state) => state.groups.items);
  const devices = useSelector((state) => state.devices.items);

  useEffect(() => {
    const deviceGroups = (device) => {
      const groupIds = [];
      let { groupId } = device;
      while (groupId) {
        groupIds.push(groupId);
        groupId = groups[groupId]?.groupId || 0;
      }
      return groupIds;
    };

    const filtered = showTerminated
      ? Object.values(devices).filter((device) => device.disabled === true)
        .filter((device) => {
          const upperCaseKeyword = keyword.toUpperCase();
          return [device.name, device.uniqueId, device.phone, device.contact, device.address, device.vin, device.vehicleMake].some((s) => s && s.toUpperCase().includes(upperCaseKeyword));
        })
      : Object.values(devices)
        .filter((device) => device.disabled === false)
        .filter((device) => !filter.statuses.length || filter.statuses.includes(device.status) || (filter.statuses.includes('terminated') && device.disabled === true))
        .filter((device) => !filter.groups.length || deviceGroups(device).some((id) => filter.groups.includes(id)))
        .filter((device) => {
          const upperCaseKeyword = keyword.toUpperCase();
          return [device.name, device.uniqueId, device.phone, device.contact, device.address, device.vin, device.vehicleMake].some((s) => s && s.toUpperCase().includes(upperCaseKeyword));
        })
        .filter((device) => {
          if (!filter.conditions || !filter.conditions.length) {
            return true;
          }
          return filter.conditions.some((condition) => isConditionMatching(device, condition));
        })
        .filter((device) => {
          if (!filter.last30DaysTravel || filter.last30DaysTravel.length === 0) {
            return true;
          }
          return filter.last30DaysTravel.some((last30DaysTravel) => {
            switch (last30DaysTravel) {
              case '2000':
                return isLast30DaysMatching(device, 2000, 3000, distanceUnit);
              case '3000':
                return isLast30DaysMatching(device, 3000, 4000, distanceUnit);
              case '4000':
                return isLast30DaysMatching(device, 4000, 5000, distanceUnit);
              case '5000':
                return isLast30DaysMatching(device, 5000, 0, distanceUnit); // Over 5000
              default:
                return false;
            }
          });
        });

    switch (filterSort) {
      case 'name':
        filtered.sort((device1, device2) => device1.name.localeCompare(device2.name));
        break;
      case 'lastUpdate':
        filtered.sort((device1, device2) => {
          const time1 = device1.lastUpdate ? moment(device1.lastUpdate).valueOf() : 0;
          const time2 = device2.lastUpdate ? moment(device2.lastUpdate).valueOf() : 0;
          return time2 - time1;
        });
        break;
      default:
        break;
    }

    setFilteredDevices(filtered);
    setFilteredPositions(filterMap
      ? filtered.map((device) => positions[device.id]).filter(Boolean)
      : Object.values(positions));
  }, [keyword, filter, showTerminated, filterSort, filterMap, groups, devices, positions, setShowTerminated, setFilteredDevices, setFilteredPositions, distanceUnit]);
};
